import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import React, {useCallback} from 'react';
import CustomPriceField from '../components/CustomPriceField';
import UserLinkField from '../components/UserLinkField';
import {ERROR_INFO} from './TossErrorShow';

function TossErrorFilter(props) {
  const errorCodeChoices = useCallback(() => {
    return ERROR_INFO.map(error => ({
      id: error.code,
      name: `${error.code}(${error.shotDesc})`,
    }));
  }, [ERROR_INFO]);

  return (
    <Filter {...props}>
      <DateInput source={'start_date'} label={'검색시작일'} alwaysOn />
      <DateInput source={'end_date'} label={'검색종료일'} alwaysOn />
      <ReferenceInput
        source="user_id"
        reference="users"
        label="판매자"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="order_id" label={'결제 ID'} alwaysOn />
      <SelectInput
        source="code"
        label="에러 코드"
        choices={errorCodeChoices()}
        alwaysOn
      />
    </Filter>
  );
}

export default function TossErrorList({...props}) {
  // build the UI elements
  return (
    <List
      filters={<TossErrorFilter />}
      bulkActionButtons={false}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" label={'ID'} />
        <ReferenceField
          reference={'users'}
          source={'userId'}
          label={'판매자 아이디'}
          linkType={'show'}>
          <TextField source={'id'} />
        </ReferenceField>
        <ReferenceField
          reference={'users'}
          source={'userId'}
          label={'판매자'}
          linkType={'show'}>
          <UserLinkField />
        </ReferenceField>
        <ReferenceField
          reference={'orders'}
          source={'orderId'}
          label={'결제내역'}
          linkType={'show'}>
          <TextField source={'id'} />
        </ReferenceField>
        <CustomPriceField source={'amount'} label={'정산 금액'} />
        <TextField source={'code'} label={'에러 코드'} />
        <DateField source="createdAt" label={'생성일'} />
      </Datagrid>
    </List>
  );
}
