import React from 'react';
import {
  SimpleForm,
  SelectInput,
  Edit,
  NumberField,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin';
import {payoutStatus} from './TossConstants';
import UserLinkField from '../components/UserLinkField';

export default function TossEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberField source="id" label={'정산 아이디'} />
        <ReferenceField
          reference={'orders'}
          source={'orderId'}
          label={'결제내역'}>
          <TextField source={'id'} />
        </ReferenceField>
        <ReferenceField
          reference={'users'}
          source={'userId'}
          label={'판매자'}
          linkType={'show'}>
          <UserLinkField />
        </ReferenceField>
        <NumberField source={'amount'} label={'정산가격'} />
        <SelectInput choices={payoutStatus} source={'status'} label={'상태'} />
        <TextField label={'지급 아이디'} source={'payoutId'} />
        <TextField label={'토스 지급 상태'} source={'tossStatus'} />
        <DateField source="createdAt" label={'생성일'} />
        <DateField source={'updatedAt'} label={'수정일'} />
        <div
          style={{
            minWidth: '20vw',
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '0px 16px',
            paddingBottom: '16px',
            marginTop: 16,
          }}>
          <h4>※ 상태 변경에 대한 설명 ※</h4>
          <p>
            정산 보류를 하려는 경우: <b>정산 대기 → 정산 보류</b>
          </p>
          <p>
            정산 취소를 하려는 경우: <b>정산 대기 → 정산 취소</b>
          </p>
          <p>
            다시 정산을 요청하려는 경우: <b>정산 보류, 정산 취소 → 정산 대기</b>
          </p>

          <b style={{color: 'red'}}>
            위의 경우를 제외한 나머지 케이스로는 변경이 불가능합니다.
          </b>
        </div>

        <div
          style={{
            width: '40vw',
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '0px 16px',
            paddingBottom: '16px',
            marginTop: 16,
          }}>
          <h4>※ 토스 지급 상태에 대한 설명 ※</h4>
          <p>
            <b>REQUESTED</b>: 지급이 요청되었지만 아직 처리되지 않은 상태입니다.
            REQUESTED 상태일 때만 지급대행 요청을 취소할 수 있습니다.
          </p>
          <p>
            <b>IN_PROGRESS</b>: 지급을 처리하고 있는 상태입니다.
          </p>
          <p>
            <b>COMPLETED</b>: 셀러에 지급이 완료된 상태입니다.
          </p>
          <p>
            <b>FAILED</b>: 지급 요청이 실패한 상태입니다.
          </p>
          <p>
            <b>CANCELED</b>: 지급 요청이 취소된 상태입니다.
          </p>
        </div>
      </SimpleForm>
    </Edit>
  );
}
