/**
 * 토스 셀러 인증 주소 복사 버튼
 * @param sellerKey
 * @returns {JSX.Element}
 * @constructor
 */
const TossVerificationUrlButton = ({tossSellerKey}) => {
  const url = `https://onboarding.tosspayments.com/payout-delegation/seller/${tossSellerKey}/registration/intro`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('토스 인증 주소 URL 클립보드에 복사되었습니다.');
      })
      .catch(err => {
        console.error('클립보드 복사 실패:', err);
      });
  };
  return (
    <button style={{height: '24px'}} onClick={copyToClipboard}>
      토스 인증 주소 복사
    </button>
  );
};

export default TossVerificationUrlButton;
