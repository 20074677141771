import React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CustomPriceField from '../components/CustomPriceField';
import UserLinkField from '../components/UserLinkField';

const styles = {
  table: {
    width: 'auto',
    tableLayout: 'fixed',
    marginTop: 4,
    marginBottom: 20,
  },
  tableHead: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    width: '100px',
    whiteSpace: 'nowrap',
    border: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  tableMediumCell: {
    width: '150px',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  tableLargeCell: {
    width: '750px',
    border: '1px solid #e0e0e0',
  },
};

export const ERROR_INFO = [
  {
    code: 'INVALID_PAYOUT_DATE',
    descKo:
      '지급일자는 과거일자 또는 공휴일로 설정할 수 없습니다. 올바른 지급일자를 입력해주세요.',
    descEn: 'The payout date cannot be set to a past date or a public holiday.',
    shotDesc: '유효하지 않은 지급일자',
  },
  {
    code: 'EXCEEDED_PAYOUT_DATE',
    descKo: '최대 지급 가능한 날짜를 초과했습니다.',
    descEn: 'The maximum payout date has been exceeded.',
    shotDesc: '지급일 초과',
  },
  {
    code: 'EXCEEDED_PAYOUT_BALANCE_AMOUNT',
    descKo: '지급 가능한 금액을 초과했습니다.',
    descEn: 'The payout amount exceeded the available balance.',
    shotDesc: '지급 가능 금액 초과',
  },
  {
    code: 'DUPLICATE_PAYOUT_REQUEST',
    descKo: '중복된 지급대행 요청입니다.',
    descEn: 'Duplicate payout request.',
    shotDesc: '중복 요청',
  },
  {
    code: 'INVALID_SELLER_INFO',
    descKo: '필수값이 누락된 셀러입니다.',
    descEn: 'The seller is missing required values.',
    shotDesc: '셀러 정보 누락',
  },
  {
    code: 'INVALID_PAYOUT_REQUEST',
    descKo: '잘못된 요청입니다.',
    descEn: 'Invalid request.',
    shotDesc: '유효하지 않은 요청',
  },
  {
    code: 'FORBIDDEN_SELLER_PAYOUT',
    descKo:
      '본인인증 또는 고객 확인 심사(KYC)가 필요한 셀러가 요청에 포함돼있습니다. PARTIALLY_APPROVED, APPROVED 상태인 셀러에게만 지급을 요청할 수 있습니다.',
    descEn:
      'The seller requires identity verification or customer verification (KYC). Payout requests can only be made for sellers with a status of PARTIALLY_APPROVED or APPROVED ',
    shotDesc: '셀러 인증 미완료',
  },
  {
    code: 'NOT_FOUND_SELLER',
    descKo: '존재하지 않는 셀러입니다.',
    descEn: 'Not found seller.',
    shotDesc: '셀러 없음',
  },
];

export default function TossErrorShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label={'ID'} />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>판매자</TableCell>
              <TableCell style={styles.tableHead}>결제내역</TableCell>
              <TableCell style={styles.tableHead}>정산금액</TableCell>
              <TableCell style={styles.tableHead}>에러코드</TableCell>
              <TableCell style={styles.tableHead}>에러메세지</TableCell>
              <TableCell style={styles.tableHead}>생성일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={styles.tableCell}>
                <ReferenceField
                  reference={'users'}
                  source={'userId'}
                  label={'판매자'}>
                  <UserLinkField />
                </ReferenceField>
              </TableCell>
              <TableCell style={styles.tableCell}>
                <ReferenceField
                  reference={'orders'}
                  source={'orderId'}
                  label={'결제내역'}>
                  <TextField source={'id'} />
                </ReferenceField>
              </TableCell>
              <TableCell style={styles.tableCell}>
                <CustomPriceField source={'amount'} />
              </TableCell>
              <TableCell style={styles.tableMediumCell}>
                <TextField source={'code'} />
              </TableCell>
              <TableCell style={styles.tableLargeCell}>
                <TextField source={'message'} />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <DateField source={'createdAt'} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h3>※ 에러코드에 대한 설명 ※</h3>
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>에러 코드</TableCell>
              <TableCell style={styles.tableHead}>한글 에러메세지</TableCell>
              <TableCell style={styles.tableHead}>영문 에러메세지</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ERROR_INFO.map(error => (
              <TableRow>
                <TableCell style={styles.tableCell}>
                  <span>{error.code}</span>
                </TableCell>
                <TableCell style={styles.tableLargeCell}>
                  <span>{error.descKo}</span>
                </TableCell>
                <TableCell style={styles.tableLargeCell}>
                  <span>{error.descEn}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleShowLayout>
    </Show>
  );
}
