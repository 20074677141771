export const payoutStatus = [
  {
    id: 'PENDING',
    name: '정산 대기',
  },
  {
    id: 'PROCESSING',
    name: '정산 진행중',
  },
  {
    id: 'SUCCESSED',
    name: '정산 성공',
  },
  {
    id: 'FAILED',
    name: '정산 실패',
  },
  {
    id: 'CANCELLED',
    name: '정산 취소',
  },
  {
    id: 'RETRYING',
    name: '재시도 대기',
  },
  {
    id: 'PAUSED',
    name: '정산 보류',
  },
];
