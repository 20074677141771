import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import React, {Fragment, useState} from 'react';
import CustomPriceField from '../components/CustomPriceField';
import {payoutStatus} from './TossConstants';
import UserLinkField from '../components/UserLinkField';
import TossPayoutAmountModal from './TossPayoutAmountModal';

function TossFilter(props) {
  return (
    <Filter {...props}>
      <DateInput source={'start_date'} label={'검색시작일'} alwaysOn />
      <DateInput source={'end_date'} label={'검색종료일'} alwaysOn />
      <ReferenceInput
        source="user_id"
        reference="users"
        label="판매자"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="order_id" label={'결제 ID'} alwaysOn />
      <SelectInput
        source={'status'}
        label={'상태'}
        choices={payoutStatus}
        alwaysOn
      />
    </Filter>
  );
}

export default function TossList({...props}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // build the UI elements
  return (
    <Fragment>
      <List
        filters={<TossFilter />}
        bulkActionButtons={false}
        sort={{field: 'id', order: 'DESC'}}
        actions={
          <TopToolbar>
            <Button
              label="토스 정산금액 현황"
              style={{color: 'green'}}
              onClick={() => setIsModalOpen(true)}></Button>
          </TopToolbar>
        }
        {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" label={'ID'} />
          <DateField source="createdAt" label={'생성일'} />
          <CustomPriceField source={'amount'} label={'정산 금액'} />
          <ReferenceField
            label={'판매자'}
            source="userId"
            reference="users"
            sortable={false}
            linkType={'show'}>
            <UserLinkField />
          </ReferenceField>
          <ReferenceField reference={'orders'} source={'orderId'}>
            <TextField source={'id'} />
          </ReferenceField>
          <SelectField
            choices={payoutStatus}
            source={'status'}
            label={'상태'}
          />
          <TextField source={'tossStatus'} label={'토스 지급상태'} />
        </Datagrid>
      </List>
      <TossPayoutAmountModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Fragment>
  );
}
