import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import React from 'react';
import {tossSellerType} from './TossSellerConstants';
import UserLinkField from '../components/UserLinkField';

function TossSellerFilter(props) {
  return (
    <Filter {...props}>
      <DateInput source={'start_date'} label={'검색시작일'} alwaysOn />
      <DateInput source={'end_date'} label={'검색종료일'} alwaysOn />
      <ReferenceInput
        source="user_id"
        reference="users"
        label="판매자"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <SelectInput
        source={'seller_type'}
        label={'타입'}
        choices={tossSellerType}
        alwaysOn
      />
    </Filter>
  );
}

export default function TossSellerList({...props}) {
  // build the UI elements
  return (
    <List
      filters={<TossSellerFilter />}
      bulkActionButtons={false}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" label={'ID'} />
        <ReferenceField
          reference={'users'}
          source={'userId'}
          label={'판매자'}
          linkType={'show'}>
          <UserLinkField />
        </ReferenceField>
        <DateField source="createdAt" label={'등록일'} />
        <SelectField
          choices={tossSellerType}
          label={'타입'}
          source={'sellerType'}
        />
        <TextField source={'status'} label={'토스 등록 상태'} />
      </Datagrid>
    </List>
  );
}
