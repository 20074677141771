import React from 'react';
import {useState} from 'react';
import {Button} from 'react-admin';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {useFetch} from '../hooks/useFetch';
import {API_URL} from '../config';

const styles = {
  table: {
    width: 'auto',
    tableLayout: 'fixed',
    marginTop: 8,
  },
  tableHead: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    width: '100px',
    whiteSpace: 'nowrap',
    border: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
};

const outOfAppModalStyles = {
  dialogContent: {},
  dialogPaperWidthMd: {
    width: '100%',
  },
  extractButton: {
    margin: '10px',
  },
};

const SUMMARY_URL = `${API_URL}/toss-payouts/summary`;

const SummaryTable = ({data}) => {
  return (
    <Table style={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell style={styles.tableHead}>구분</TableCell>
          {data.map((item, idx) => (
            <TableCell key={idx} style={styles.tableHead}>
              {item.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={styles.tableHead}>해당 건수</TableCell>
          {data.map((item, idx) => (
            <TableCell key={idx} style={styles.tableCell}>
              {item.count}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={styles.tableHead}>해당 금액</TableCell>
          {data.map((item, idx) => (
            <TableCell key={idx} style={styles.tableCell}>
              {`${new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 0}).format(item.amount)}원`}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const TossPayoutAmountModal = withStyles(outOfAppModalStyles)(({
  classes,
  basePath,
  open,
  onClose,
  push,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const data = useFetch(SUMMARY_URL);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
      <DialogTitle>토스 정산금액 현황</DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        {data?.result && <SummaryTable data={data?.result} />}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});

export default connect(null, {
  push,
})(TossPayoutAmountModal);
