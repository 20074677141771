import React from 'react';
import {
  FunctionField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {BANK_LIST} from '../utils/banks';
import {tossSellerType} from './TossSellerConstants';
import UserLinkField from '../components/UserLinkField';
import TossVerificationUrlButton from '../components/TossVerificationUrlButton';

const styles = {
  table: {
    width: 'auto',
    tableLayout: 'fixed',
    marginTop: 4,
    marginBottom: 16,
  },
  tableHead: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    width: '100px',
    whiteSpace: 'nowrap',
    border: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  tableMediumCell: {
    width: '150px',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
};

const ComponentByType = () => {
  const record = useRecordContext();

  const renderComponent = () => {
    switch (record.businessType) {
      case 'INDIVIDUAL':
        return (
          <div>
            <h3>개인 정보</h3>
            <Table style={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.tableHead}>이름</TableCell>
                  <TableCell style={styles.tableHead}>핸드폰</TableCell>
                  <TableCell style={styles.tableHead}>이메일</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={styles.tableCell}>
                    <TextField source="individual.name" />
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <TextField source="individual.phone" />
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <TextField source="individual.email" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      case 'INDIVIDUAL_BUSINESS':
      case 'CORPORATE':
        return (
          <div>
            <h3>사업자 정보</h3>
            <Table style={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.tableHead}>이름</TableCell>
                  <TableCell style={styles.tableHead}>대표자명</TableCell>
                  <TableCell style={styles.tableHead}>핸드폰</TableCell>
                  <TableCell style={styles.tableHead}>이메일</TableCell>
                  <TableCell style={styles.tableHead}>사업자번호</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={styles.tableMediumCell}>
                    <TextField source="company.name" />
                  </TableCell>
                  <TableCell style={styles.tableMediumCell}>
                    <TextField source="company.representativeName" />
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <TextField source="company.phone" />
                  </TableCell>
                  <TableCell style={styles.tableMediumCell}>
                    <TextField source="company.email" />
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <TextField source="company.businessRegistrationNumber" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
    }
  }; // 현재 레코드를 가져오는 훅

  return <div>{renderComponent()}</div>;
};

const SplitReferenceField = props => {
  const record = useRecordContext(props);
  if (!record || !record[props.source]) return null;

  const splittedValue = record[props.source].split(':')[0];

  const newRecord = {...record, _splitId: splittedValue};

  return (
    <ReferenceField
      {...props}
      record={newRecord}
      source="_splitId"
      linkType={'show'}>
      {props.children}
    </ReferenceField>
  );
};

export default function TossSellerShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <h3>기본 정보</h3>
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>토스 셀러 아이디</TableCell>
              <TableCell style={styles.tableHead}>ref 셀러 아이디</TableCell>
              <TableCell style={styles.tableHead}>셀러 아이디</TableCell>
              <TableCell style={styles.tableHead}>셀러 등록 상태</TableCell>
              <TableCell style={styles.tableHead}>셀러 타입</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={styles.tableCell}>
                <TextField source="tossSellerId" />
                <br />
                <FunctionField
                  addLabel={false}
                  render={record =>
                    record.tossSellerId ? (
                      <TossVerificationUrlButton
                        tossSellerKey={record.tossSellerId}
                      />
                    ) : null
                  }
                />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <TextField source="refSellerId" />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <SplitReferenceField source="refSellerId" reference="users">
                  <UserLinkField />
                </SplitReferenceField>
              </TableCell>
              <TableCell style={styles.tableCell}>
                <TextField source="status" />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <SelectField
                  choices={tossSellerType}
                  source={'businessType'}
                  optionValue={'id'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div
          style={{
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '4px 16px',
          }}>
          <h4>※ 셀러 등록 상태에 대한 설명 ※</h4>
          <p>
            <b>APPROVAL_REQUIRED</b>: 지급대행이 불가능한 상태입니다. 개인 및
            개인사업자 셀러 등록 직후의 상태이며, 본인인증이 필요합니다.
          </p>
          <p>
            <b>PARTIALLY_APPROVED</b>: 일주일 동안 1천만원까지 지급대행이 가능한
            상태입니다. 등록 직후의 법인사업자 셀러 또는 본인인증을 완료한 개인
            및 개인사업자 셀러의 상태입니다.
          </p>
          <p>
            <b>KYC_REQUIRED</b>: 지급대행이 불가능한 상태입니다. 일주일 동안
            1천만원을 초과하는 금액을 지급 요청하면 셀러는 해당 상태로
            변경됩니다. 셀러가 KYC 심사를 완료해야 합니다.
          </p>
          <p>
            <b>APPROVED</b>: 금액 제한 없이 지급대행이 가능한 상태입니다. KYC
            심사가 정상적으로 완료된 셀러의 상태입니다.
          </p>
        </div>

        <h3>계좌 정보</h3>
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>예금주</TableCell>
              <TableCell style={styles.tableHead}>은행명</TableCell>
              <TableCell style={styles.tableHead}>계좌번호</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={styles.tableMediumCell}>
                <TextField source="account.holderName" />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <SelectField
                  source={'account.bankCode'}
                  choices={BANK_LIST}
                  optionValue={'code'}
                  optionText={'name'}
                />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <TextField source="account.accountNumber" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <ComponentByType />
      </SimpleShowLayout>
    </Show>
  );
}
