import React from 'react';
import {
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import {payoutStatus} from './TossConstants';
import CustomPriceField from '../components/CustomPriceField';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import UserLinkField from '../components/UserLinkField';

const styles = {
  table: {
    width: 'auto',
    tableLayout: 'fixed',
    marginTop: 8,
  },
  tableHead: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    width: '100px',
    whiteSpace: 'nowrap',
    border: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
};

export default function TossShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="id" label={'정산 아이디'} />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>결제 내역</TableCell>
              <TableCell style={styles.tableHead}>판매자</TableCell>
              <TableCell style={styles.tableHead}>정산 금액</TableCell>
              <TableCell style={styles.tableHead}>상태</TableCell>
              <TableCell style={styles.tableHead}>지급 아이디</TableCell>
              <TableCell style={styles.tableHead}>토스 지급 상태</TableCell>
              <TableCell style={styles.tableHead}>생성일</TableCell>
              <TableCell style={styles.tableHead}>수정일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={styles.tableCell}>
                <ReferenceField
                  reference={'orders'}
                  source={'orderId'}
                  label={'결제내역'}>
                  <TextField source={'id'} />
                </ReferenceField>
              </TableCell>
              <TableCell style={styles.tableCell}>
                <ReferenceField
                  reference={'users'}
                  source={'userId'}
                  label={'판매자'}
                  linkType={'show'}>
                  <UserLinkField />
                </ReferenceField>
              </TableCell>
              <TableCell style={styles.tableCell}>
                <CustomPriceField source={'amount'} />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <SelectField
                  choices={payoutStatus}
                  source={'status'}
                  label={'상태'}
                />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <TextField label={'지급 아이디'} source={'payoutId'} />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <TextField label={'토스 지급 상태'} source={'tossStatus'} />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <DateField source="createdAt" label={'생성일'} />
              </TableCell>
              <TableCell style={styles.tableCell}>
                <DateField source={'updatedAt'} label={'수정일'} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div
          style={{
            width: '40vw',
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '0px 16px',
            paddingBottom: '16px',
            marginTop: 16,
          }}>
          <h4>※ 토스 지급 상태에 대한 설명 ※</h4>
          <p>
            <b>REQUESTED</b>: 지급이 요청되었지만 아직 처리되지 않은 상태입니다.
            REQUESTED 상태일 때만 지급대행 요청을 취소할 수 있습니다.
          </p>
          <p>
            <b>IN_PROGRESS</b>: 지급을 처리하고 있는 상태입니다.
          </p>
          <p>
            <b>COMPLETED</b>: 셀러에 지급이 완료된 상태입니다.
          </p>
          <p>
            <b>FAILED</b>: 지급 요청이 실패한 상태입니다.
          </p>
          <p>
            <b>CANCELED</b>: 지급 요청이 취소된 상태입니다.
          </p>
        </div>
      </SimpleShowLayout>
    </Show>
  );
}
