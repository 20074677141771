// in src/App.js
import React from 'react';
import {Admin, Resource, fetchUtils} from 'react-admin';
import JssProvider from 'react-jss/lib/JssProvider';
// import { Person, Store, Shop, RateReview, Explore, FormatSize, Category, Search } from '@material-ui/icons';
import myRestProvider from './dataProvider'
import authProvider from "./authProvider";

import addUploadFeature from './utils/addUploadFeature';
import {generateClassName} from './utils/CustomClassnameGenerator';
import NotFound from './NotFound'

import {UserList, UserShow, UserEdit, UserCreate} from './users'
import {ProductList, ProductShow, ProductEdit, ProductCreate} from './products'
import {OrderList, OrderShow, OrderEdit} from './orders'
import {ReviewList} from './reviews'
import {CategoryCreate, CategoryEdit, CategoryList, SizeCreate, SizeEdit, SizeList} from "./categories";
import {BrandCreate,BrandEdit,BrandList} from "./brands";
import {CollectionList, CollectionEdit, CollectionCreate} from "./collections";
import AuditList from './audits/AuditList';
import LoginAuditList from './loginAudits/LoginAuditList';
import ReportEdit from "./reports/ReportEdit";
import ReportList from "./reports/ReportList";
import PostCreate from "./posts/PostCreate";
import PostEdit from "./posts/PostEdit";
import PostList from "./posts/PostList";
import SectionCreate from "./sections/SectionCreate";
import SectionEdit from "./sections/SectionEdit";
import SectionList from "./sections/SectionList";
import SettlementShow from "./settlements/SettlementShow";
import SellerCollectionCreate from './sellerCollections/SellerCollectionCreate';
import SellerCollectionEdit from './sellerCollections/SellerCollectionEdit';
import SellerCollectionList from './sellerCollections/SellerCollectionList';
import SettlementList from "./settlements/SettlementList";
import UserAdminCreate from "./userAdmins/UserAdminCreate";
import UserAdminEdit from "./userAdmins/UserAdminEdit";
import UserAdminList from "./userAdmins/UserAdminList";
import StyleList from './styles/StyleList';
import StyleCreate from './styles/StyleCreate';
import StyleEdit from './styles/StyleEdit';
import OnboardingLookbookList from './onboardingLookbooks/OnboardingLookbookList';
import OnboardingLookbookCreate from './onboardingLookbooks/OnboardingLookbookCreate';
import OfflineOrderEdit from './offlineOrders/OfflineOrderEdit';
import OfflineOrderList from './offlineOrders/OfflineOrderList';
import NotificationList from "./notifications/NotificationList";
import NotificationCreate from "./notifications/NotificationCreate";
import NotificationEdit from "./notifications/NotificationEdit";
import BulkProductList from "./bulkProduct/BulkProductList";
import BulkProductCreate from "./bulkProduct/BulkProductCreate";
import BulkProductEdit from "./bulkProduct/BulkProductEdit";
import CrawledProductList from "./crawledProduct/CrawledProductList";
import CrawledProductEdit from "./crawledProduct/CrawledProductEdit";
import BulkProductShow from "./bulkProduct/BulkProductShow";
import CouponList from './coupons/CouponList';
import CouponCreate from './coupons/CouponCreate';
import CouponEdit from './coupons/CouponEdit';
import UserCouponList from './userCoupons/UserCouponList';
import UserCouponEdit from './userCoupons/UserCouponEdit';
import ReferralCodeList from "./referralCode/ReferralCodeList";
import ReferralCodeCreate from "./referralCode/ReferralCodeCreate";
import ReferralCodeShow from "./referralCode/ReferralCodeShow";
import RecommendStickerList from "./recommendSticker/RecommendStickerList";
import RecommendStickerCreate from "./recommendSticker/RecommendStickerCreate";
import RecommendeeList from "./recommendee/RecommendeeList";
import RecommendeeCreate from "./recommendee/RecommendeeCreate";
import RecommendStickerShow from "./recommendSticker/RecommendStickerShow";
import ServiceInfoEdit from "./serviceInfo/ServiceInfoEdit";
import MessageList from "./messages/MessageList";
import TossList from "./tossPayouts/TossList";
import TossShow from './tossPayouts/TossShow';
import TossSellerList from './tossSeller/TossSellerList';
import TossSellerShow from './tossSeller/TossSellerShow';
import TossErrorList from './tossErrors/TossErrorList';
import TossErrorShow from './tossErrors/TossErrorShow';
import TossEdit from './tossPayouts/TossEdit';

const App = () => (
    <JssProvider generateClassName={generateClassName} >
        <Admin
            catchAll={NotFound}
            dataProvider={addUploadFeature(myRestProvider)}
            authProvider={authProvider}>
            <Resource options={{label: 'User'}} name="users" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate}  />
            <Resource options={{label: 'Products'}} name="products" list={ProductList} show={ProductShow} edit={ProductEdit} create={ProductCreate}  />
            <Resource options={{label: 'ES-Products'}} name="es/products" list={ProductList} show={ProductShow} edit={ProductEdit} create={ProductCreate}  />
            <Resource options={{label: 'Order'}} name="orders" list={OrderList} show={OrderShow} edit={OrderEdit}  />
            <Resource options={{label: 'Offline Order'}} name="offline-orders" list={OfflineOrderList}  edit={OfflineOrderEdit} />
            <Resource name="settlements" list={SettlementList} show={SettlementShow} />
            <Resource options={{label: '토스 셀러'}} name="toss-sellers" list={TossSellerList} show={TossSellerShow}/>
            <Resource options={{label: '토스 정산'}} name="toss-payouts" list={TossList} show={TossShow} edit={TossEdit}/>
            <Resource options={{label: '토스 정산 에러'}} name="toss-errors" list={TossErrorList} show={TossErrorShow}/>
            <Resource options={{label: 'Review'}} name="reviews" list={ReviewList} />
            <Resource options={{label: 'Collection'}} name="collections" list={CollectionList} create={CollectionCreate} edit={CollectionEdit} />
            <Resource options={{label: 'Seller Collections'}} name="guide-collections" list={SellerCollectionList} edit={SellerCollectionEdit} create={SellerCollectionCreate} />
            <Resource name="posts" list={PostList} create={PostCreate} edit={PostEdit} />
            <Resource options={{label: 'Lookbook Collection'}} name="onboarding-lookbook" list={OnboardingLookbookList} create={OnboardingLookbookCreate}/>
            <Resource name="reports" list={ReportList} edit={ReportEdit} />
            <Resource options={{label: 'Category'}} name="categories" list={CategoryList} edit={CategoryEdit} create={CategoryCreate}  />
            <Resource options={{label: 'Size'}} name="sizes" list={SizeList} create={SizeCreate} edit={SizeEdit}  />
            <Resource options={{label: 'Brand'}} name="brands" list={BrandList} create={BrandCreate} edit={BrandEdit} />
            <Resource options={{label: 'Style'}} name="styles" list={StyleList} create={StyleCreate} edit={StyleEdit} />
            <Resource name="sections" list={SectionList} create={SectionCreate} edit={SectionEdit} />
            <Resource options={{label: 'Admin User'}} name="user_admins" list={UserAdminList} create={UserAdminCreate} edit={UserAdminEdit} />
            <Resource options={{label: 'Audit'}} name="audits" list={AuditList} />
            <Resource options={{label: 'Login Audit'}} name="login_audits" list={LoginAuditList} />
            <Resource options={{label: 'Notifications'}} name="push-reservations" list={NotificationList} create={NotificationCreate} edit={NotificationEdit} />
            <Resource options={{label: 'Bulk Product'}} name="platform-apply" list={BulkProductList} create={BulkProductCreate} edit={BulkProductEdit} show={BulkProductShow} />
            <Resource options={{label: 'Crawled Product'}} name="platform-temp-product" list={CrawledProductList} edit={CrawledProductEdit} />
            <Resource options={{label: 'Coupon'}} name="coupons" list={CouponList} create={CouponCreate} edit={CouponEdit}/>
            <Resource options={{label: 'User Coupon'}} name="user-coupons" list={UserCouponList} edit={UserCouponEdit}/>
            <Resource options={{label: 'Referral Code'}} name="referral_codes" list={ReferralCodeList} create={ReferralCodeCreate} show={ReferralCodeShow} />
            <Resource options={{label: 'Recommend Sticker'}} name="recommend-stickers" list={RecommendStickerList} create={RecommendStickerCreate} show={RecommendStickerShow} />
            <Resource options={{label: 'Recommendee'}} name="recommendees" list={RecommendeeList} create={RecommendeeCreate} />
            <Resource options={{label: 'Message'}} name="messages"  list={MessageList} />
            <Resource options={{label: 'ServiceInfo'}} name="fruits-service-info"  list={ServiceInfoEdit} />
            {/* Hidden */}
            <Resource options={{label: 'Collection_products'}} name="collection_products" />
            <Resource options={{label: 'Product_bumps'}} name="product_bumps" />
            <Resource name="admin_notes" />
            <Resource name='reports-user'/>
            <Resource name='reports-filters'/>
        </Admin>
    </JssProvider>
)
export default App;

//LocalStorage Customization
Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function (key) {
    return JSON.parse(this.getItem(key));
}

