import React from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter, FunctionField,
    ImageField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useListContext,
    useNotify,
    useRefresh,
    useUnselectAll
} from 'react-admin';
import {withStyles} from "@material-ui/core";

import UserLinkField from "../components/UserLinkField";
import Button from "@material-ui/core/Button";
import {API_URL} from "../config";
import {loadAccessToken} from "../lib/Auth";

const reportTypeChoices = [
    { id: 'post', name: 'post'},
    { id: 'post_comment', name: 'post_comment'},
    { id: 'product', name: 'product'},
    { id: 'user', name: 'user'},
    { id: 'system', name: 'system'},
]

const reportModerationDecisionChoices = [
    { id: 'HIDDEN', name: 'HIDDEN'},
    { id: 'IGNORED', name: 'IGNORED'},
    { id: 'BANNED', name: 'BANNED'},
    { id: 'null', name: '처리 필요'},
]

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 106.6667, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'cover'
        }
    },
    hidden: {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    ignored: {
        backgroundColor: '#BDBDBD',
        color: 'white',
    },
    banned: {
        backgroundColor: '#D32F2F',
        color: 'white',
    },
};

/**
 * Styling for a report row
 * @param record the current data record
 * @return the style properties
 */
function reportRowStyle(record) {
    return {
        backgroundColor: record.moderationDecision ? undefined : '#fff4e5'
    };
}

// Multiple Report Api
const updateReportStatus = async (reportIdList, type, notify, refresh, unselectAll) => {
    try {
        const res = await fetch(`${API_URL}/reports`, {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                Authorization: `Bearer ${loadAccessToken()}`
            }),
            body: JSON.stringify({ reportIdList, moderationDecision: type })
        });

        if (res.ok) {
            const successData = await res.json();
            notify(successData.result, { type: 'info' });
            refresh();
            if (unselectAll) unselectAll('reports');
        } else {
            const errorData = await res.json();
            const errorMessage = errorData.error;
            notify(`업데이트 실패: ${errorMessage}`, { type: 'warning' });
        }
    } catch (error) {
        notify(`${error.message}`, { type: 'error' });
    }
};

/**
 * bulkActionButtons - 신고 상태 multi update
 * @returns {Element}
 * @constructor
 */
function BulkUpdateReportStatusButton() {
    const { selectedIds } = useListContext();
    const notify = useNotify();  // Move hooks outside the conditional
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const handleBulkUpdate = async (type) => {
        await updateReportStatus(selectedIds, type, notify, refresh, unselectAll);
    };

    return (
        <>
            <Button onClick={() => handleBulkUpdate(reportModerationDecisionChoices[0].id)}
                    variant="contained" color={'primary'} size="small">
                HIDDEN(신고승인)
            </Button>
            <Button onClick={() => handleBulkUpdate(reportModerationDecisionChoices[1].id)}
                    variant="contained" style={styles.ignored} size="small">
                IGNORE(신고거절)
            </Button>
            <Button onClick={() => handleBulkUpdate(reportModerationDecisionChoices[2].id)}
                    variant="contained" style={styles.banned} size="small">
                BANNED(즉시숨김)
            </Button>
        </>
    );
}

/**
 * Quick 신고 처리 버튼 - 리스트에서 사용
 * @param record
 * @param notify
 * @param refresh
 * @returns {Element}
 */
function renderQuickReportButtons(record, notify, refresh) {
    const handleButtonClick = async (e, type) => {
        e.stopPropagation();
        await updateReportStatus([record.id], type, notify, refresh);
    };

    return (
        <>
            <Button onClick={(e) => handleButtonClick(e, reportModerationDecisionChoices[0].id)}
                    variant="contained"
                    size="small"
                    color="primary"
            >
                승인(HIDDEN)
            </Button>
            <Button onClick={(e) => handleButtonClick(e, reportModerationDecisionChoices[1].id)}
                    variant="contained"
                    size="small"
                    style={styles.ignored}
            >
                거절(IGNORED)
            </Button>
            <Button onClick={(e) => handleButtonClick(e, reportModerationDecisionChoices[2].id)}
                    variant="contained"
                    size="small"
                    style={styles.banned}
            >
                숨김(BANNED)
            </Button>
        </>
    );
}

/**
 * 신고를 하거나 신고를 표시하는 컴포넌트
 * @param record
 * @param notify
 * @param refresh
 * @returns {Element}
 * @constructor
 */
function QuickReportDisplay({ record, notify, refresh }) {
    if (!record.moderationDecision) {
        return renderQuickReportButtons(record, notify, refresh);
    }
    return <TextField source="moderationDecision" />;
}

/**
 * Sub-component for filtering a list of reports.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function ReportFilter(props) {
    return (
        <Filter {...props}>
            {/* generic report details */}
            <DateInput source="createdAt_from" label="created from" alwaysOn/>
            <DateInput source="createdAt_until" label="created until" alwaysOn/>
            <TextInput label="reporter_id" source="reporter_id" alwaysOn />
            <ReferenceInput source="reporter_id" reference="users" label="신고자" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <ReferenceInput source="report_filter_id" reference="reports-filters" label="신고종류필터" perPage={100} alwaysOn>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="reason" alwaysOn/>
            <SelectInput choices={reportTypeChoices} source="report_type" alwaysOn />
            <SelectInput choices={reportModerationDecisionChoices} source="moderationDecision" alwaysOn />

            {/* content report */}
            <TextInput label="user_id" source="user_id" alwaysOn />
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <ReferenceInput source="product_id" reference="products" alwaysOn>
                <AutocompleteInput optionText="title"/>
            </ReferenceInput>
            <TextInput label="product_id" source="product_id" alwaysOn />
            <ReferenceInput source="post_id" reference="posts" alwaysOn>
                <AutocompleteInput optionText="description"/>
            </ReferenceInput>
            <TextInput label="post_id" source="post_id" alwaysOn />
        </Filter>
    );
}

/**
 * Component for a list of reports.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function ReportList({classes, ...props}) {
    const notify = useNotify();
    const refresh = useRefresh();
    // build the UI elements
    return (
        <List bulkActionButtons={<>
           <BulkUpdateReportStatusButton />
        </>} filters={<ReportFilter/>} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="edit" rowStyle={reportRowStyle} isRowSelectable={(record) =>
                record.moderationDecision === null}
            >
                {/* generic report details */}
                <DateField source="createdAt" label="신고일"/>
                <ReferenceField source="reporter.id" reference="users" label="신고자" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="report_filter.name" label="신고필터유형" />
                <TextField source="reason" label="신고 사유"/>
                <ImageField source="images.0.url" label="신고 이미지" sortable={false} className={classes.image}/>

                {/* user report */}
                <ReferenceField source="user.id" reference="users" label="User" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
                <TextField label="총 상품수" source="userStats.productCount"/>
                <DateField label="가입 날짜" source="user.createdAt"/>
                <TextField label="총 신고 수" source="userStats.reportCount"/>
                <TextField label="후기 수" source="userStats.reviewCount"/>

                {/* product report */}
                <ImageField source="product.resizedSmallImages.0.url" label="Product Image" sortable={false} className={classes.image}/>
                <TextField source="product.title" label="Product" />

                {/* post report */}
                <ImageField source="post.resizedSmallImages.0.url" label="Post Image" sortable={false} className={classes.image}/>
                <TextField source="post.description" label="Post"/>
                <ReferenceField source="post.user_id" reference="users" label="Post Author" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* post comment report */}
                <TextField source="post_comment.text" label="Comment" sortable={false}/>
                <ReferenceField source="post_comment.user_id" reference="users" label="Comment Author" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* moderation status */}
                <DateField source="moderatedAt"/>
                <FunctionField
                    label="moderationDecision"
                    render={record => <QuickReportDisplay record={record} notify={notify} refresh={refresh} />}
                />
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(ReportList);
