import React, {useMemo} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import ReactJson from 'react-json-view';

import UserLinkField from '../components/UserLinkField';

const actionChoices = [
    {value: 'insert', name: 'Insert'},
    {value: 'update', name: 'Update'},
    {value: 'upsert', name: 'Upsert'},
    {value: 'delete', name: 'Delete'},
];

const typeChoices = [
    {value: 'user', name: 'user'},
    {value: 'seller', name: 'seller'},
    {value: 'settlement', name: 'settlement'},
    {value: 'order_payment', name: 'order_payment'},
    {value: 'order', name: 'order'},
    {value: 'report', name: 'report'},
    {value: 'review', name: 'review'},
    {value: 'product', name: 'product'},
    {value: 'product_discount', name: 'product_discount'},
    {value: 'post', name: 'post'},
    {value: 'post_comment', name: 'post_comment'},
    {value: 'block', name: 'block'},
    {value: 'delivery_address', name: 'delivery_address'},
    {value: 'left_user', name: 'left_user'},
    {value: 'negotiation', name: 'negotiation'},
    {value: 'recommenend_coupon_exchange', name: 'recommenend_coupon_exchange'},
    {value: 'used_coupon', name: 'used_coupon'},
    {value: 'user_coupon', name: 'user_coupon'},
    {value: 'user_admin', name: 'user_admin'},
];

// component styling
const styles = {
    date: {
        whiteSpace: 'nowrap'
    },
    description: {
        display: 'block',
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

/**
 * Subcomponent for the data json preview.
 * @param record The audit log entry.
 * @constructor
 */
export function AuditViewer({record}) {
    const changedData = useMemo(
        () => Object.fromEntries(
            Object.entries(record.data)
                .filter(([key]) => !record.changed || record.changed.includes(key))
        ),
        [record.data]
    );
    return (
        <ReactJson src={changedData} name={record.auditableType}/>
    );
}

/**
 * Subcomponent for filtering a list of audit logs.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function AuditFilter(props) {
    return (
        <Filter {...props}>
            <DateInput source="createdAt_from" label="created from" alwaysOn/>
            <DateInput source="createdAt_until" label="created until" alwaysOn/>
            <NumberInput source='user_id'label="User id" alwaysOn/>
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <SelectInput source="auditable_type" label="Type" choices={typeChoices} optionValue="value" allowEmpty={true} alwaysOn/>
            <SelectInput source="action" choices={actionChoices} optionValue="value" allowEmpty={true} alwaysOn/>
            <TextInput source="ip_address" alwaysOn/>
            <TextInput source="user_agent" alwaysOn/>
        </Filter>
    )
}

/**
 * Component for a list of audits.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function AuditList({classes, ...props}) {
    return (
        <List {...props} bulkActionButtons={false} filters={<AuditFilter/>} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid rowClick="show" expand={<AuditViewer/>}>
                <DateField label="Date" source="createdAt" showTime className={classes.date}/>
                <ReferenceField source="user_id" reference="users" allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
                <TextField label="Type" source="auditableType" className={classes.description}/>
                <TextField label="Id" source="auditableId" className={classes.description}/>
                <TextField source="action" className={classes.description}/>
                <TextField source="ipAddress" className={classes.description}/>
                <TextField source="userAgent" className={classes.description}/>
            </Datagrid>
        </List>
    )
}

export default withStyles(styles)(AuditList);